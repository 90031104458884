import React from 'react';
import { PageProps, Link } from 'gatsby';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import NotFoundImage from '../components/images/NotFoundImage';
import SmallContainer from '../components/layout/SmallContainer';
import Container from '../components/layout/Container';

const NotFound: React.FunctionComponent<PageProps> = () => (
  <Layout>
    <MetaData title="Siden kunne ikke findes" />
    <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Vi kunne desværre ikke finde siden</h1>
          <p>Du kan gå tilbage til forsiden eller skrive til os.</p>
        </Container>
        <SmallContainer className="pt-4 text-center">
          <NotFoundImage />
        </SmallContainer>
      </div>
  </Layout>
);

export default NotFound;
